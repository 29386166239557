<template>
  <div id="projects" class="pl-4 pt-4">
    <v-card
      class="d-flex align-center justify-space-between mb-1 pl-2 pr-4"
      flat
      tile
    >
      <v-card flat>
        <h1>
          {{
            $route.params.archived
              ? "Liste des projets archivés"
              : "Liste des projets en cours"
          }}
        </h1>
      </v-card>
      <v-card flat class="mr-2"
        ><MediumButtonSlot
          v-if="$hasRight('workspaces.createWorkspaceBtn') && !isArchive"
          @click="dialog = true"
          >Créer un nouveau projet</MediumButtonSlot
        >
      </v-card>
    </v-card>
    <v-card flat class="lighten-4 mb-3 pl-2 pr-4" style="width: 100%">
      <v-card flat color="transparent">
        <div class="align-center justify-space d-flex">
          <div
            class="pa-0 ml-1 mr-2 filter"
            v-if="$hasRight('globalActions.showSearchProjectBtn')"
          >
            <v-text-field
              :label="$t('search_project')"
              :placeholder="$t('search_project')"
              append-icon="mdi-magnify"
              @keydown.enter="onAppendClick"
              @click:append="onAppendClick"
              @click:clear="onClearTextField"
              @change="onChangeName"
              hide-details
              v-model="textInput"
              class="pt-0"
              clearable
              :disabled="textFieldDisabled"
              :loading="textFieldLoading"
            ></v-text-field>
          </div>
          <div
            class="pa-0 ml-2 mr-2 filter"
            v-if="$hasRight('globalActions.showOrganizationsSelect')"
          >
            <v-select
              @input="filterName = $event || ''"
              :items="organizations"
              v-model="orgField"
              :label="$t('filter_organization')"
              :disabled="selectOrganizationDisabled"
              :loading="selectOrganizationLoading"
              clearable
              hide-details
              @click:clear="onClearOrganization"
            >
              <!-- <template v-slot:append-outer>
                <v-btn
                  v-if="orgField != null"
                  small
                  icon
                  @click.stop.prevent="onClearOrganization"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </template> -->
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="orgField = null">
                  <v-list-item-content
                    :class="{
                      ' primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted':
                        !orgField,
                    }"
                  >
                    <v-list-item-title>
                      {{ $t("all_fem") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </div>
          <div class="pa-0 ml-2 filter">
            <v-select
              v-if="
                ($hasRight('globalActions.showAgenciesSelect') &&
                  orgField !== null) ||
                (!$hasRight('globalActions.showOrganizationsSelect') &&
                  $hasRight('globalActions.showAgenciesSelect'))
              "
              @input="filterName = $event || ''"
              :label="$t('filter_agency')"
              :items="
                !$hasRight('globalActions.showOrganizationsSelect') &&
                $hasRight('globalActions.showAgenciesSelect')
                  ? customerAgencies.map((agency) => {
                      return {
                        text: agency.name,
                        value: agency.id,
                        disabled: false,
                        orgId: agency.organization.id,
                      };
                    })
                  : availableToSelectAgencies
              "
              @change="onChangeAgency"
              @click:clear="onClearAgency"
              hide-details
              v-model="agencyField"
              :disabled="selectAgencyDisabled"
              :loading="selectAgencyLoading"
              clearable
            >
              <!-- <template v-slot:append-outer>
                <v-btn
                  v-if="agencyField != null"
                  small
                  icon
                  @click.stop.prevent="onClearAgency"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </template> -->
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="agencyField = null"
                >
                  <v-list-item-content
                    :class="{
                      ' primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted':
                        !agencyField,
                    }"
                  >
                    <v-list-item-title>
                      {{ $t("all") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </div>
          <div class="d-flex flex-grow-1 flex-row-reverse pa-0 mr-3 filter">
            <MediumButtonSlot
              v-if="isResetButtonDisplayed"
              @click="removeFilters"
              style="width: 252px"
            >
              Reinitialiser les filtres
            </MediumButtonSlot>
          </div>
        </div>
      </v-card>
    </v-card>
    <div class="scrollable_container pr-4">
      <v-row class="ma-0">
        <v-col
          cols="12"
          sm="6"
          class="pa-2"
          v-for="(project, index) in projects"
          :key="index"
        >
          <project-card
            :project="project"
            :key="project.id"
            @update:archived="fetchWorkspace()"
            @update:project="updateProject"
          />
        </v-col>
      </v-row>
    </div>

    <project-creation-modale
      v-if="dialog"
      @createProject="createProject"
      @closeModale="closeModale"
    ></project-creation-modale>
  </div>
</template>

<script>
import ProjectCard from "../components/workspaces/Workspace.card.vue";
import ProjectCreationModale from "../components/workspaces/CreateWorkspace.modal.vue";
import auth from "../service/auth";

export default {
  components: {
    ProjectCard,
    ProjectCreationModale,
  },
  name: "Workspaces",
  data() {
    return {
      projects: [],
      dialog: false,

      textInput: null,
      textFieldDisabled: false,
      selectOrganizationDisabled: false,
      selectAgencyDisabled: false,
      textFieldLoading: false,
      selectAgencyLoading: false,
      selectOrganizationLoading: false,
      agencies: [],
      organizations: [],
      agencyField: null,
      orgField: null,
      loading: true,
      customerAgencies: [],
    };
  },
  computed: {
    availableToSelectAgencies() {
      return this.orgField !== null
        ? this.agencies.filter(
            (agency) =>
              agency.organizationId === this.orgField || agency.value === 0
          )
        : this.agencies;
    },
    isArchive() {
      return this.$route.params.archived === "archived";
    },
    isResetButtonDisplayed() {
      return (
        this.orgField != null || this.agencyField != null || this.textInput
      );
    },
  },
  mounted() {
    this.fetchWorkspace();
    this.getCustomerAgencies();
    this.getInternalAgencies();
  },
  watch: {
    $route() {
      this.fetchWorkspace();
    },
    orgField() {
      this.agencyField = null;
    },
  },
  methods: {
    closeModale() {
      this.dialog = false;
    },
    async getCustomerAgencies() {
      this.customerAgencies = await this.$api.agencies.findAll(true);
    },
    async getInternalAgencies() {
      const agencies = await this.$api.agencies.findAll(false);

      this.internalAgencies = agencies.filter(
        (agency) => agency.organization.isInternal
      );
    },
    fetchWorkspace() {
      if (undefined === this.$store.state.user.current.userId)
        return setTimeout(this.fetchWorkspace, 300);
      this.$api.workspaces
        .findByUser(this.$store.state.user.current.userId, {
          isArchived: this.$route.params.archived === "archived",
        })
        .then((workspaces) => {
          this.projects = workspaces;

          let agencies = [];
          let organizations = [];
          this.projects.forEach((el) => {
            let {
              agency: { name, id, organization },
            } = el;

            let { name: orgName, id: orgId } = organization;
            organizations.push({
              text: orgName,
              value: orgId,
              disabled: false,
            });
            agencies.push({
              text: name,
              value: id,
              disabled: false,
              organizationId: orgId,
            });
          });

          this.agencies = agencies;
          this.organizations = organizations;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createProject(workspaceForm) {
      this.$api.workspaces.create(workspaceForm).then((workspace) => {
        this.dialog = false;
        let newProject = workspace;
        this.projects = [newProject, ...this.projects];
      });
    },
    onChangeOrganization(value) {
      if (value == 0) {
        this.onClearOrganization();
      } else if (value != undefined) {
        this.filterProjects("organization", value);
        this.textFieldDisabled = true;
        this.selectOrganizationLoading = true;
      }
    },
    onChangeAgency(value) {
      if (value != undefined) {
        this.filterProjects("agency", value);
        this.textFieldDisabled = true;
        this.selectAgencyLoading = true;
      }
    },
    onChangeName(value) {
      if (value != undefined) {
        this.selectOrganizationDisabled = true;
        this.selectAgencyDisabled = true;
      } else {
        this.selectOrganizationDisabled = false;
        this.selectAgencyDisabled = false;
      }
    },
    onAppendClick() {
      this.filterProjects("name", this.textInput);
      this.textFieldLoading = true;
      this.textFieldDisabled = true;
    },
    onClearTextField() {
      this.getAllWorkspaces();
      this.textFieldLoading = true;
    },
    onClearOrganization() {
      this.getAllWorkspaces();
      this.textFieldDisabled = false;
      this.selectAgencyDisabled = false;
      this.selectOrganizationLoading = true;
      this.orgField = null;
    },
    onClearAgency() {
      this.getAllWorkspaces();
      this.textFieldDisabled = false;
      this.selectOrganizationDisabled = false;
      this.selectAgencyLoading = true;
      this.agencyField = null;
    },
    filterProjects(filterBy, query) {
      let params = { filterBy: filterBy, query: query };
      if (this.isArchive) {
        params.isArchived = true;
      }
      this.$api.workspaces
        .findByUser(auth.decodeToken().userId, params)
        .then((workspaces) => {
          this.projects = workspaces;

          this.textFieldLoading = false;
          this.selectOrganizationLoading = false;
          this.selectAgencyLoading = false;

          if (filterBy == "name") {
            this.textFieldDisabled = false;
          }
        });
    },
    getAllWorkspaces() {
      if (undefined === this.$store.state.user?.current?.userId)
        return setTimeout(this.getAllWorkspaces, 500);
      this.$api.workspaces
        .findByUser(auth.decodeToken().userId, {
          isArchived: this.$route.params.archived === "archived",
        })
        .then((workspaces) => {
          this.projects = workspaces;
          this.textFieldLoading = false;
          this.selectOrganizationLoading = false;
          this.selectAgencyLoading = false;
        });
    },
    removeFilters() {
      this.getAllWorkspaces();

      this.textInput = null;
      this.orgField = null;
      this.agencyField = null;

      this.textFieldDisabled = false;
      this.selectAgencyDisabled = false;
      this.selectOrganizationDisabled = false;

      this.textFieldLoading = true;
      this.selectOrganizationLoading = true;
      this.selectAgencyLoading = true;
    },
    updateProject(data) {
      this.projects = this.projects.map((el) => (el.id == data.id ? data : el));
    },
  },
};
</script>

<style lang="scss">
#projects {
  height: 100%;
  display: flex;
  flex-direction: column;

  .filter {
    width: 24%;
  }

  .tooltip_input {
    height: 15px;
    width: 50px;
    margin-right: 5px;
    border-radius: 5px;
    background-color: #ebebeb;
  }

  .v-menu {
    &__content {
      box-shadow: none;
      border: 1px solid black;
    }
  }
  .v-text-field .v-input__append-inner {
    align-self: auto;
  }
  .v-select {
    input::placeholder {
      color: black;
    }
  }
  .v-text-field {
    padding-top: 0px;
  }
}
</style>
