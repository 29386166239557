<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Modifier les informations du projet</span>
      </v-card-title>
      <v-form
        ref="form"
        @submit.prevent="modifyProject()"
        class="form"
        v-model="isFormValid"
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autofocus
                  tabindex="1"
                  label="Nom du projet"
                  :rules="[$rules.required, $rules.min5Chars]"
                  clearable
                  outlined
                  v-model="form.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  tabindex="2"
                  label="Description"
                  :rules="[$rules.required, $rules.min30Chars]"
                  outlined
                  v-model="form.description"
                  auto-grow
                  clearable
                >
                </v-textarea>
              </v-col>
              <v-row>
                <v-col cols="6">
                  <v-select
                    tabindex="3"
                    :items="customerOrganizations"
                    item-text="name"
                    item-value="id"
                    :rules="[$rules.required]"
                    label="Organisation client"
                    required
                    clearable
                    outlined
                    :loading="customerOrganizations.length === 0"
                    v-model="form.customerOrganizationId"
                  ></v-select>
                  <v-select
                    tabindex="4"
                    :items="organizations"
                    item-text="name"
                    item-value="id"
                    label="Organisation prestataire"
                    :rules="[$rules.required]"
                    clearable
                    outlined
                    :loading="loading"
                    v-model="form.organizationId"
                    return-object
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    tabindex="5"
                    :items="availableCustomerAgencies"
                    item-text="name"
                    item-value="id"
                    :rules="[$rules.required]"
                    label="Client"
                    required
                    clearable
                    outlined
                    :loading="customerAgencies.length === 0"
                    v-model="form.customerAgencyId"
                  ></v-select>
                  <v-select
                    :items="availableAgencies"
                    item-text="name"
                    tabindex="6"
                    item-value="id"
                    label="Agence interne"
                    :rules="[$rules.required]"
                    clearable
                    outlined
                    :loading="loading"
                    v-model="form.agencyId"
                  ></v-select>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <LowButtonSlot @click="hideModal"> Fermer </LowButtonSlot>
          <v-spacer></v-spacer>
          <HighButtonSlot :_disabled="!isFormValid" _type="submit">
            Modifier
          </HighButtonSlot>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "WorkspaceModifyModal",
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      isFormValid: true,
      form: {
        name: this.project.name,
        description: this.project.description,
        customerOrganizationId: this.project.customerAgency.organization.id,
        customerAgencyId: this.project.customerAgency.id,
        organizationId: this.project.agency.organization.id,
        agencyId: this.project.agency.id,
      },
      customerAgencies: [],
      customerOrganizations: [],
      organizations: [],
      agencies: [],
      userAgency: { id: "" },
    };
  },
  watch: {
    "form.agencyId": {
      handler: function (newId, before) {
        // let found = this.agencies.find((ag) => ag.id == newId);
        // if (found.length > 0)
        //   this.form.organizationId = found[0].organization.id;
        if (this.userAgency.id !== newId && before === this.userAgency.id)
          this.$toast(
            "warning",
            "Vous êtes sur le point d'attribuer une agence différente de la vôtre"
          );
      },
      deep: true,
    },
  },
  computed: {
    availableAgencies() {
      if (!this.form.organizationId?.id) return this.agencies;

      return this.agencies.filter(
        (agency) => agency.organization.id === this.form.organizationId.id
      );
    },
    availableCustomerAgencies() {
      if (!this.form.customerOrganizationId) return this.customerAgencies;

      return this.customerAgencies.filter(
        (agency) => agency.organization.id === this.form.customerOrganizationId
      );
    },
  },
  methods: {
    async getUserAgency() {
      const response = await this.$axios.get(
        "/users/" + this.$store.state.user.current.userId
      );
      this.userAgency = response.data.agency;
    },
    async showModal() {
      this.loading = true;
      this.agencies = await this.$api.agencies.findByOrganization(
        this.project.agency.organization.id
      );
      this.agencies.forEach((agency) => {
        this.organizations.push(agency.organization);
      });
      this.customerAgencies = await this.$api.agencies.findAll(true);
      this.customerAgencies.forEach((agency) => {
        this.customerOrganizations.push(agency.organization);
      });
      this.loading = false;
      this.show = true;
    },
    async modifyProject() {
      const project = await this.$api.workspaces.edit(
        this.project.id,
        this.form
      );
      this.$emit("update:project", project);
    },
    hideModal() {
      this.show = false;
    },
  },
  mounted() {
    this.$root.$on("closeCurrent", this.hideModal);
  },
  created() {
    this.unwatchOpen = this.$watch("show", (newVal) => {
      if (newVal) {
        this.getUserAgency().then(() => {
          this.unwatchOpen();
        });
      }
    });
  },
};
</script>

<style scoped></style>
