<template>
  <section class="ProjectCreationModale">
    <v-card flat outlined class="ProjectCreationModale__card">
      <v-card-title>
        <span class="text-h5">Créer un projet</span>
      </v-card-title>
      <v-form ref="form" @submit.prevent="createProject()" class="form">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  label="Nom du projet*"
                  autofocus
                  tabindex="1"
                  required
                  outlined
                  clearable
                  v-model="workspaceForm.name"
                  :rules="[$rules.required, $rules.min5Chars]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12">
                <v-textarea
                  tabindex="2"
                  label="Description*"
                  required
                  outlined
                  v-model="workspaceForm.description"
                  :rules="[$rules.required]"
                  class="m_textarea"
                  clearable
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12">
                <div class="inputBtn">
                  <v-autocomplete
                    label="Organisation client*"
                    tabindex="3"
                    required
                    outlined
                    clearable
                    v-model="workspaceForm.customerOrganizationId"
                    :rules="[$rules.required]"
                    :items="customerOrganizations"
                    :loading="customerOrganizations.length === 0"
                    item-text="name"
                    item-value="id"
                    @change="getCustomerAgenciesByOrganization()"
                  ></v-autocomplete>
                  <medium-button-slot
                    v-if="$hasRight('organizations.create')"
                    @click="openCreateOrgModale(false)"
                    >+</medium-button-slot
                  >
                </div>
                <div class="inputBtn">
                  <v-autocomplete
                    :disabled="null === workspaceForm.customerOrganizationId"
                    label="Agence client*"
                    tabindex="4"
                    required
                    outlined
                    clearable
                    v-model="workspaceForm.customerAgencyId"
                    :rules="[$rules.required]"
                    :items="customerAgencies"
                    :loading="
                      this.customerAgencies.length === 0 &&
                      null !== workspaceForm.customerOrganizationId
                    "
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                  <medium-button-slot
                    :_disabled="null === workspaceForm.customerOrganizationId"
                    v-if="$hasRight('agencies.create')"
                    @click="openAgencyCreationModal(false)"
                    >+</medium-button-slot
                  >
                </div>
                <div class="inputBtn">
                  <v-autocomplete
                    label="Organisation NextRoad*"
                    tabindex="5"
                    required
                    outlined
                    clearable
                    v-model="workspaceForm.organizationId"
                    :rules="[$rules.required]"
                    :items="internalOrganizations"
                    :loading="internalOrganizations.length === 0"
                    item-text="name"
                    item-value="id"
                    @change="getInternalAgenciesByOrganization()"
                  ></v-autocomplete>
                  <medium-button-slot
                    v-if="$hasRight('organizations.create')"
                    @click="openCreateOrgModale(true)"
                    >+</medium-button-slot
                  >
                </div>
                <div class="inputBtn">
                  <v-autocomplete
                    :disabled="null === workspaceForm.organizationId"
                    label="Agence NextRoad*"
                    tabindex="6"
                    required
                    outlined
                    clearable
                    v-model="workspaceForm.agencyId"
                    :rules="[$rules.required]"
                    :items="internalAgencies"
                    :loading="
                      this.internalAgencies.length === 0 &&
                      null !== workspaceForm.organizationId
                    "
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                  <medium-button-slot
                    :_disabled="null === workspaceForm.organizationId"
                    v-if="$hasRight('agencies.create')"
                    @click="openAgencyCreationModal(true)"
                    >+</medium-button-slot
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <MediumButtonSlot @click="closeModale()"> Fermer </MediumButtonSlot>
          <v-spacer></v-spacer>
          <HighButtonSlot type="submit"> Créer </HighButtonSlot>
        </v-card-actions>
      </v-form>
    </v-card>
    <CreateOrganizationModale
      v-if="isCreateOrganizationModaleOpened"
      @organizationCreated="handleNewOrg"
      @closePopin="isCreateOrganizationModaleOpened = false"
    />
    <AgencyCreateModale
      v-if="isAgencyCreateModaleOpen"
      @agencyCreated="handleNewAgency"
      :organization="userOrganization"
      @closePopin="isAgencyCreateModaleOpen = false"
    />
  </section>
</template>

<script>
import CreateOrganizationModale from "@/components/entities/organization/CreateOrganization.modal";
import AgencyCreateModale from "../entities/agency/CreateAgency.modal.vue";

export default {
  name: "ProjectCreateModale",
  components: {
    AgencyCreateModale,
    CreateOrganizationModale,
  },
  data() {
    return {
      isNewOrgInternal: false,
      isNewAgencyInternal: false,
      customerAgencies: [],
      internalAgencies: [],
      organizations: [],
      userOrganization: {
        id: "",
      },
      isCreateOrganizationModaleOpened: false,
      isAgencyCreateModaleOpen: false,
      workspaceForm: {
        name: "",
        description: null,
        customerOrganizationId: null,
        customerAgencyId: null,
        organizationId: null,
        agencyId: null,
      },
      rules: {
        required: (value) => !!value || "Requis",
        len: (value) => value.length >= 5 || "Minimum 5 caractères",
      },
      userAgency: {
        id: "",
      },
    };
  },
  computed: {
    customerOrganizations() {
      return this.organizations.filter((org) => {
        return false === org.isInternal;
      });
    },
    internalOrganizations() {
      return this.organizations.filter((org) => {
        return true === org.isInternal;
      });
    },
  },
  watch: {
    "workspaceForm.agencyId": {
      handler: function (newId, before) {
        if (this.userAgency.id !== newId && before === this.userAgency.id)
          this.$toast(
            "warning",
            "Vous êtes sur le point d'attribuer une agence différente de la vôtre"
          );
      },
      deep: true,
    },
  },
  methods: {
    openCreateOrgModale(internal) {
      this.isNewOrgInternal = internal;
      this.isCreateOrganizationModaleOpened = true;
    },
    handleNewOrg(org) {
      this.organizations.push(org);
      if (this.isNewOrgInternal) this.workspaceForm.organizationId = org.id;
      else this.workspaceForm.customerOrganizationId = org.id;
    },
    handleNewAgency(agency) {
      if (this.isNewAgencyInternal) {
        this.internalAgencies.push(agency);
        this.workspaceForm.agencyId = agency.id;
      } else {
        this.customerAgencies.push(agency);
        this.workspaceForm.customerAgencyId = agency.id;
      }
    },
    async getInternalAgenciesByOrganization() {
      this.internalAgencies = await this.$api.agencies.findByOrganization(
        this.workspaceForm.organizationId
      );
    },
    async getCustomerAgenciesByOrganization() {
      this.customerAgencies = await this.$api.agencies.findByOrganization(
        this.workspaceForm.customerOrganizationId
      );
    },
    async getUserOrganization() {
      const userId = this.$store.state.user.current.userId;
      if (undefined === userId)
        return setTimeout(this.getUserOrganization, 500);
      const response = await this.$axios.get(
        "/users/" + userId + "/organizations"
      );
      this.userOrganization = response.data;
    },
    async getOrganizations() {
      this.organizations = await this.$api.organizations.findAll();
    },
    refreshViewData() {
      this.getUserAgency();
    },
    openAgencyCreationModal(internal) {
      this.isNewAgencyInternal = internal;
      this.isAgencyCreateModaleOpen = true;
    },
    async getUserAgency() {
      const user = await this.$api.users.findById(
        this.$store.state.user.current.userId
      );
      this.userAgency = user.agency;
      this.workspaceForm.agencyId = user.agency.id;
    },
    createProject() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("createProject", this.workspaceForm);
    },
    closeModale() {
      this.$emit("closeModale");
    },
    refreshModale() {
      this.getUserAgency();
      this.getUserOrganization();
    },
  },
  mounted() {
    this.$root.$on("closeCurrent", this.closeModale);
    this.getUserAgency();
    this.getUserOrganization();
    this.getOrganizations();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_extends.scss";

.ProjectCreationModale {
  @extend %popinView;

  &__card {
    max-width: 600px;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
  .v-select .v-input__append-inner {
    align-self: flex-start !important;
  }
  .inputBtn {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .MediumButtonSlot {
      margin-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      height: 100%;
    }
  }
}
</style>
