<template>
  <div class="height100">
    <v-card class="mx-auto project_card height100" width="auto" outlined>
      <v-card-title>
        <div class="project_card__card_title">
          <b>{{ project.name }}</b>
        </div>
      </v-card-title>
      <v-card-subtitle class="card__subtitle"
        ><span
          ><b>{{ campaignsCount }}</b></span
        ></v-card-subtitle
      >
      <v-card-text class="card_text">
        <div class="mb-3 description">
          <div :class="{ expanded: desc_expanded, retracted: !desc_expanded }">
            {{ project.description }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="card__actions width100">
        <v-row class="width100">
          <v-col class="d-flex flex-row-reverse mb-2 pr-2">
            <high-button-slot
              :_to="{ name: 'project', params: { id: project.id } }"
            >
              Accéder
            </high-button-slot>
          </v-col>
        </v-row>
      </v-card-actions>
      <div class="action_box">
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="$hasRight('workspaces.editWorkspaceBtn')"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="m_brown">mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0" width="15vh">
            <v-list-item @click="showModal()">
              <v-list-item-title>Éditer</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="archiveProject({ isArchived: true })"
              v-if="!project.isArchived"
            >
              <v-list-item-title>Archiver</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="archiveProject({ isArchived: false })"
              v-if="project.isArchived"
            >
              <v-list-item-title>Désarchiver</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-card>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ project.agency.organization.name }}
        </v-card-title>

        <v-card-text>
          <br />
          <p><b>Agence</b> : {{ project.agency.name }}</p>
          <p>
            <b>Adresse</b> : {{ project.agency.address.street }},
            {{ project.agency.address.zipcode }}
            {{ project.agency.address.city }},
            {{ project.agency.address.country }}
          </p>
          <p><b>Téléphone</b> : {{ project.agency.contact.phone }}</p>
          <p><b>Mobile</b> : {{ project.agency.contact.mobile }}</p>
          <p><b>Email</b> : {{ project.agency.email }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <medium-button-slot @click="dialog = false">
            Fermer
          </medium-button-slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <workspace-modify-modal
      ref="modal"
      :workspaceId="$route.params.id"
      :project="project"
      v-on:update:project="updateProject"
    />
  </div>
</template>

<script>
import WorkspaceModifyModal from "./UpdateWorkspace.modal";

export default {
  name: "ProjectCard",
  components: { WorkspaceModifyModal },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      desc_expanded: false
    };
  },
  computed: {
    campaignsCount() {
      const globalCount = this.project.campaignsCount;
      if (0 === globalCount.totalCount) return "Aucune campagne";
      let sentence = "";

      if (0 < globalCount.auscultation) {
        sentence +=
          globalCount.auscultation +
          " campagne" +
          (globalCount.auscultation > 1 ? "s" : "") +
          " d'auscultation";
      }

      if (0 < globalCount.asbestosPAHTH) {
        if (0 < globalCount.auscultation) sentence += " / ";
        sentence +=
          globalCount.asbestosPAHTH +
          " campagne" +
          (globalCount.asbestosPAHTH > 1 ? "s" : "") +
          " Amiante/HAP/HCT";
      }

      if (0 < globalCount.structural) {
        if (0 < globalCount.asbestosPAHTH) sentence += " / ";
        sentence +=
          globalCount.structural +
          " campagne" +
          (globalCount.structural > 1 ? "s" : "") +
          " structurelle" +
          (globalCount.structural > 1 ? "s" : "");
      }

      return sentence;
    }
  },
  methods: {
    showModal() {
      this.$refs.modal.showModal();
    },
    hideModal() {
      this.$ref.modal.hideModal();
    },
    updateProject(data) {
      this.$refs.modal.hideModal();
      this.$emit("update:project", data);
    },
    archiveProject(archived) {
      const action = archived.isArchived === true ? "archiver" : "désarchiver";
      this.$toast(
        "info",
        "Êtes vous sûr de vouloir " + action + " le projet ?",
        action,
        async () => {
          const workspace = await this.$api.workspaces.editArchived(
            this.project.id,
            archived.isArchived
          );

          this.$emit("update:archived", workspace);
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.project_card {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  &__card_title {
    max-width: calc(100% - 36px);
    height: 60px;
    display: flex;
    align-items: center;
  }
  .action_box {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.project_card__card_title {
  word-break: normal;
  align-items: start;
}

.card__actions {
  //position: absolute;
  flex-direction: column;
  bottom: 5px;
}
.card_text {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  flex: 1;
}

.width100 {
  width: 100%;
}
.height100 {
  height: 100%;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 1.7;
}

.description {
  line-height: 1.5em;
  min-height: 4.5em;
  overflow-y: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.retracted {
  height: 4.5em;
}
.expanded {
  height: auto;
}
</style>
